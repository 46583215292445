@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$font-primary: 'Poppins', sans-serif;

$color-primary: #FE5A5F !default;
$color-green: #58D2B5 !default;
$color-white: #ffffff !default;

:root {
  --toastify-color-dark: #16212c;
  --toastify-color-info: #3333ad;
  --toastify-color-success: #58D2B5 !important;
  --toastify-color-warning: #e0c041;
  --toastify-color-error: #FE5A5F !important;
  --toastify-color-progress-dark: rgba(22, 33, 44, 0.2);
  --toastify-color-progress-info: rgba(22, 33, 44, 0.2);
  --toastify-color-progress-success: rgba(22, 33, 44, 0.2);
  --toastify-color-progress-warning: rgba(22, 33, 44, 0.2);
  --toastify-color-progress-error: rgba(22, 33, 44, 0.2);
}
