.main {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: linear-gradient(112deg, #03112C 82.88%, #EC5B6D 100%);
  .contact-button {
    height: 50px;
    margin: 24px auto;
    position: absolute;
    top: 0;
    max-width: 1200px;
    width: 100%;
    padding: 0 60px;
    a {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      color: #fff;
      padding: 12px 28px;
      border-radius: 60px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      position: absolute;
      right: 60px;
      top: 0;
    }
  }
  .container {
    overflow: hidden;
    padding: 60px;
  }
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &-section {
    display: flex;
    align-items: center;
  }
  &-box {
    padding: 30px 0;
    width: calc(100% - 560px);
    transform: translateX(calc(100% - 240px));
    margin-right: 90px;
    animation: textBoxAnimation 0.3s ease-in-out 0.5s forwards;
    transition: all .3s ease;
    h2 {
      color: rgba($color-white, 0.87);
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 266%;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 16px;
      height: 48px;
      position: relative;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        animation: elementsAnimation .3s ease-in-out 0.75s  forwards;
        transition: all .3s ease;
      }
      img {
        display: block;
        margin-left: 12px;
      }
    }
    h1 {
      color: $color-white;
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1px;
      margin-bottom: 32px;
      span {
        display: block;
        height: 60px;
        position: relative;
        b {
          white-space: nowrap;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          animation: elementsAnimation .3s ease-in-out 0.75s  forwards;
          transition: all .3s ease;
          .today {
            font-style: normal;
            position: relative;
            &:before {
              content: '';
              position: absolute;
              height: 2px;
              background-color: $color-white;
              top: 50%;
              left: 0;
              width: 0;
              animation: lineTroughAnimation .3s ease-in-out 1s forwards;
              transition: all .3s ease;
            }
          }
          .now {
            display: inline-block;
            background: linear-gradient(82deg, #FE5A5F 8.5%, #701B9D 99.08%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-style: normal;
            opacity: 0;
            position: absolute;
            top: 0;
            right: -120px;
            transform: translateX(-100px);
            animation: fadeInTextAnimation .3s ease-in-out 1s forwards;
            transition: all .3s ease;
          }
        }
      }
    }
    .arrow {
      position: relative;
      span {
        display: block;
        width: 0;
        position: absolute;
        height: 155px;
        overflow: hidden;
        top: -90px;
        left: 250px;
        animation: arrowAnimation .3s ease-in-out 1s forwards;
        transition: all .3s ease;
        img {
          display: block;
          width: 290px;
        }
      }
    }
    ul {
      margin-bottom: 32px;
      li {
        color: rgba($color-white, 0.87);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 266%;
        letter-spacing: 1px;
        text-transform: uppercase;
        height: 45px;
        position: relative;
        div {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          white-space: nowrap;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          animation: elementsAnimation .3s ease-in-out 0.75s  forwards;
          transition: all .3s ease;
        }
        img {
          display: block;
          margin-right: 16px;
        }
      }
    }
    .get-started {
      position: relative;
      height: 50px;
      & > div {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        animation: elementsAnimation .3s ease-in-out 0.75s  forwards;
        transition: all .3s ease;
        label {
          width: 0;
          overflow: hidden;
          transition: all .3s ease;
          input {
            height: 50px;
            width: 350px;
            padding: 0 80px 0 12px;
            color: rgba(3, 17, 44, 0.40);
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            border-radius: 12px 0 0 12px;
            overflow: hidden;
            border: none;
            background-image: url('../../images/envelop.svg');
            background-repeat: no-repeat;
            background-position: right 12px center;
          }
          //.error-message {
          //  position: absolute;
          //  left: 0;
          //  bottom: -35px;
          //  color: #FF5A5F;
          //}
        }
        button {
          color: $color-white;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          border-radius: 100px;
          background: #FE5A5F;
          padding: 12px 28px;
          &.preregister-button {
            border-radius: 0 100px 100px 0;
          }
        }
      }
      &.open {
        & > div {
          label {
            width: 350px;
          }
        }
      }
    }
  }
  .video-box {
    width: 470px;
    height: 600px;
    border-radius: 50px;
    border: 1px solid #6615A0;
    background: linear-gradient(0deg, #15223B 0%, #15223B 100%), #03112C;
    box-shadow: 0 4px 64px 0 rgba(0, 0, 0, 0.65);
    padding: 33px 40px;
    transform: translateX(150%);
    animation: videoBoxAnimation 0.3s ease-in-out 0.5s forwards;
    transition: all .3s ease;
    & > div {
      width: 100%;
      height: 100%;
      position: relative;
    }
    video {
      border: 1px solid rgba(255, 255, 255, 0.07);
      background: #15223B;
      border-radius: 50px;
    }
  }
}
