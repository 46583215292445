.payment-success {
  max-width: 767px;
  width: 100%;
  margin: 0 auto;
  .top {
    background: rgba(250, 250, 251, 1);
    padding: 270px 24px 24px;
    background-image: url('../../../assets/images/success-bg-image.svg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 300px;
    img {
      display: block;
      width: 55px;
      margin-bottom: 16px;
    }
  }
  .bottom {
    padding: 32px 20px;
    p {
      font-size: 18px;
      line-height: 27px;
      color: rgba(3, 17, 44, 1);
    }
  }
}
