.container {
  padding: 0 20px;
  padding-top: 20px;
  .title {
    text-align: center;
    font-size: 32px;
  }
  .subTitle {
    font-size: 24px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .list {
    margin-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .link {
    text-decoration: solid;
  }
}
