@keyframes textBoxAnimation {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes videoBoxAnimation {
  from {
    transform: translateX(150%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes elementsAnimation {
  0% {
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    left: 0;
    transform: translateX(0);
  }
}
@keyframes lineTroughAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes fadeInTextAnimation {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes arrowAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 290px;
  }
}
@keyframes videoLoaderAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes videoAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

